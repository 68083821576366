<template>
  <div>
    <b-row v-if="!isLoading">
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2 ">
            <i class="text-primary ti ti-school small-gap-right" />
            <h4 class="text-primary mb-0">
              Daftar Kelas
            </h4>
          </div>

          <!-- Table of Student-->
          <div>
            <b-row>
              <!-- Display Row -->
              <b-col lg="1">
                <b-form-select
                  class="w-auto mr-1"
                  v-model="pageLength"
                  :options="options"
                />
              </b-col>

              <!-- Table Filter -->
              <b-col></b-col>
              <b-col lg="4">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Filter</label>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search"
                      type="text"
                      class="d-inline-block mr-1"
                    />

                    <!-- add button -->
                    <b-button
                      variant="primary"
                      class="d-flex"
                      :to="{
                        name: checkAuthorizeRole('classrooms-add'),
                      }"
                    >
                      <i class="ti ti-plus small-gap-right" />
                      Tambah
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Table -->
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Customize Column -->

                <!-- Column: Name -->
                <span
                  v-if="props.column.field === 'classroomName'"
                  class="text-nowrap"
                >
                  <b-avatar variant="light-primary" class="mx-1">
                    <i class="ti" :class="props.row.classroomIcon" />
                  </b-avatar>
                  <span class="font-weight-bolder text-nowrap">{{
                    pascalCase(props.row.classroomName)
                  }}</span>
                </span>

                <!-- Column: Status -->
                <span
                  v-else-if="props.column.field === 'status'"
                  class="text-nowrap"
                >
                  <b-form-group>
                    <v-select
                      v-model="props.row.status"
                      :options="['Active', 'Inactive']"
                      @input="handleClassStatus(props.row, props.row.status)"
                    />
                  </b-form-group>
                </span>

                <!-- Column: Action -->
                <span
                  v-else-if="props.column.field === 'action'"
                  class="d-flex justify-content-start"
                >
                  <b-button
                    variant="info"
                    size="sm"
                    class="mr-1"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Login Sebagai Guru"
                    @click="handleLoginAs(props.row.teacherId)"
                  >
                    <i class="ti ti-login" />
                  </b-button>
                  <b-button
                    variant="warning"
                    size="sm"
                    class="mr-1"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Edit Data"
                    :to="{
                      name: checkAuthorizeRole('classrooms-edit'),
                      params: { id: props.row.id },
                    }"
                  >
                    <i class="ti ti-pencil" />
                  </b-button>
                  <b-button
                    variant="success"
                    size="sm"
                    class="mr-1"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Tambah Data Siswa"
                    :to="{
                      name: checkAuthorizeRole('classrooms-add-students'),
                      params: { id: props.row.id },
                    }"
                  >
                    <i class="ti ti-users" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- Pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select
                      v-model="pageLength"
                      hidden
                      class="w-auto mr-1"
                      :options="options"
                      @input="
                        props.perPageChanged({ currentPerPage: pageLength })
                      "
                    />
                    <span class="text-nowrap ">
                      Showing
                      {{
                        pageLength * currentPage <= total
                          ? pageLength * currentPage
                          : total
                      }}
                      of {{ total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          currentPage = value
                          props.pageChanged({ currentPage: value })
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- Loading State -->
    <div v-if="isLoading" class="d-flex justify-content-center vertical-center">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Data Kelas
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// * Components
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

// * Utils
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'
import { pascalCase } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,

    VueGoodTable,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {},
  data() {
    return {
      // State
      isLoading: true,

      // Table option
      searchTerm: '',
      currentPage: 1,
      pageLength: 10,
      total: 0,
      options: ['10', '20', '30'],
      columns: [
        {
          label: 'Nama Kelas',
          field: 'classroomName',
        },
        {
          label: 'Pengajar',
          field: 'teacher',
        },
        {
          label: 'Jumlah Siswa',
          field: 'studentCount',
        },
        {
          label: 'Jadwal',
          field: 'schedule',
          width: '15%',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Aksi',
          field: 'action',
          width: '5%',
        },
      ],
      rows: [],
    }
  },
  watch: {
    pageLength() {
      this.refreshTableData()
    },
    currentPage() {
      this.refreshTableData()
    },
    searchTerm() {
      this.refreshTableData()
    },
  },
  async mounted() {
    const params = {
      page: this.currentPage,
      per_page: this.pageLength,
      search: this.searchTerm,
    }

    await client
      .get('/school-admin/classrooms', { params })
      .then((response) => {
        const classrooms = response.data.data.data
        this.total = response.data.data.total

        const listOfClassrooms = classrooms.map((classroom) => {
          return {
            classroomIcon: classroom.logo,
            classroomName: classroom.name,
            teacher: classroom.teacher.name,
            studentCount: classroom.students_count,
            schedule: classroom.schedule,
            id: classroom.id,
            status: classroom.status,
            teacherId: classroom.teacher.id,
          }
        })

        this.rows = listOfClassrooms
        this.isLoading = false
      })
      .catch((error) => {
        this.showToast(
          'error',
          'XIcon',
          'Gagal',
          'Data Gagal Dimuat, Silahkan Coba Lagi'
        )
      })
  },
  methods: {
    pascalCase,
    checkAuthorizeRole,
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },

    // Handle Change Status
    handleClassStatus(classroom, status) {
      this.setClassroomStatus(classroom.id, status)
    },
    async setClassroomStatus(classroomId, status = 'Inactive') {
      const data = {
        status,
      }

      await client
        .post(`school-admin/classrooms/${classroomId}/action`, data)
        .then(() => {
          this.refreshTableData().then(() => {
            this.buttonLoadingId = ''
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Status Kelas berhasil diupdate'
            )
          })
        })
    },

    async refreshTableData() {
      const params = {
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
      }

      await client
        .get('/school-admin/classrooms', { params })
        .then((response) => {
          const classrooms = response.data.data.data
          this.total = response.data.data.total

          const listOfClassrooms = classrooms.map((classroom) => {
            return {
              classroomIcon: classroom.logo,
              classroomName: classroom.name,
              teacher: classroom.teacher.name,
              studentCount: classroom.students_count,
              schedule: classroom.schedule,
              id: classroom.id,
              status: classroom.status,
              teacherId: classroom.teacher.id,
            }
          })

          this.rows = listOfClassrooms
          this.isLoading = false
        })
        .catch((error) => {
          this.showToast(
            'error',
            'XIcon',
            'Gagal',
            'Data Gagal Dimuat, Silahkan Coba Lagi'
          )
        })
    },

    handleLoginAs(adminId) {
      client.post(`/auth/login/${adminId}`).then((response) => {
        // store previous user data on local storage
        const previousUserData = JSON.parse(localStorage.getItem('userData'))
        previousUserData.token = localStorage.getItem('accessToken')
        localStorage.setItem('userDataList', JSON.stringify([previousUserData]))

        // store new user data on local storage
        const { token } = response.data.data
        let userData = response.data.data.user

        const userAbility = [
          {
            action: 'manage',
            subject: 'all',
          },
        ]

        userData = {
          ...userData,
          ability: userAbility,
          token,
        }

        // replace localstorage with new userdata
        localStorage.setItem('userData', JSON.stringify(userData))
        this.$ability.update(userData.ability)
        localStorage.setItem('accessToken', token)

        // redirect
        this.$router.push({ name: 'teacher-dashboard' }).then(() => {
          this.$router.go()
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.line-numbers {
  padding: 0 1.2em !important;
}

.schedule-filter {
  min-width: 150px !important;
}

.vs__clear {
  display: none;
}
</style>
